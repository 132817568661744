import * as bootstrap from 'bootstrap';
import Aos from 'aos';
import axios from 'axios';

Aos.init({
  once: true,
});

document.addEventListener('DOMContentLoaded', function() {
  /* UI COMPONENTS */
  // Modals
  var appointmentModalEl = document.getElementById('appointmentModal');
  var appointmentModalEmailInput = document.querySelector('#appointmentModal #email-input');

  // Buttons
  var basicDataCheckBtn = document.getElementById('basicDataCheckBtn');
  var anaCreditCheckBtn = document.getElementById('anaCreditCheckBtn');
  var customEngineBtn = document.getElementById('customEngineBtn');
  var freeAnalysisBtn = document.getElementById('freeAnalysisBtn');

  // Checkboxes
  var basicDataCheckCbx = document.getElementById('basicDataCheckCbx');
  var anaCreditCheckCbx = document.getElementById('anaCreditCheckCbx');
  var customEngineCbx = document.getElementById('customEngineCbx');
  var freeAnalysisCbx = document.getElementById('freeAnalysisCbx');

  /* CONTACT FORM */
  // Contact Form Input Elements
  var contactNameInput = document.getElementById('namedqs');
  var contactMailInput = document.getElementById('emaildqs');
  var contactMessageTextArea = document.getElementById('messagedqs');
  var contactSubmitButton = document.getElementById('mainContactSubmit');
  
  // Contact Form H*ney P0t Inputs
  var contactHpName = document.querySelector('#mainContactForm #name');
  var contactHpMail = document.querySelector('#mainContactForm #email');
  
  // On Submit Contact Form
  var mainContactForm = document.getElementById('mainContactForm');
  mainContactForm.addEventListener('submit', function(e) {
    e.preventDefault();

    // Sanitizing & validating is happening on server-side.
    var formData = new FormData();
    formData.append('namedqs', contactNameInput.value);
    formData.append('emaildqs', contactMailInput.value);
    formData.append('messagedqs', contactMessageTextArea.value);

    // H*ney P0t fields
    formData.append('name', contactHpName.value);
    formData.append('email', contactHpMail.value);

    // Send Post Request for contact form
    axios({
      method: "post",
      url: "backend/public/contact.php",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function(response) {
        showNotificationToast('Erfolg!', '', 'Mail wurde versand!');

        // Reset UI
        contactSubmitButton.disabled = true;
        contactNameInput.value = '';
        contactMailInput.value = '';
        contactMessageTextArea.value = '';
      })
      .catch(function(response) {
        showNotificationToast('Fehler!', 'Danke für Ihr Verständnis', 'Mail konnte nicht gesendet werden!<br>Bitte kontaktieren Sie uns direkt.');
      });
  });

  /* UI UTILS */
  // Reset checkboxes to unselected
  var resetCheckboxes = function() {
    basicDataCheckCbx.checked = false;
    anaCreditCheckCbx.checked = false;
    customEngineCbx.checked = false;
    freeAnalysisCbx.checked = false;
  };

  // Init Bootstrap Toasts
  var notificationToastEl = document.getElementById('notificationToast');
  var notificationToast = new bootstrap.Toast(notificationToastEl, { delay: 10000 });

  // Toast Helper
  var showNotificationToast = function(title, small, body) {
    notificationToast.show();
    notificationToastEl.addEventListener('shown.bs.toast', function() {
      document.querySelector('#notificationToast .dq-toast-title').textContent = title;
      document.querySelector('#notificationToast .dq-toast-small').textContent = small;
      document.querySelector('#notificationToast .toast-body').innerHTML = body;
    }, { once: true });
  };

  /* APPOINTMENT MODAL */
  // Contact Form Input Elements
  var appointmentMailInput = document.getElementById('emaildqs2');
  var appointmentPhoneInput = document.getElementById('phonedqs');

  // Appointment Form H*ney P0t Inputs
  var appointmentHpName = document.querySelector('#mainAppointmentForm #fullname');
  var appointmentHpMail = document.querySelector('#mainAppointmentForm #mail');

  var mainAppointmentForm = document.getElementById('mainAppointmentForm');
  mainAppointmentForm.addEventListener('submit', function(e) {
    e.preventDefault();

    // Sanitizing & validating is happening on server-side.
    var formData = new FormData();
    formData.append('emaildqs', appointmentMailInput.value);
    formData.append('phonedqs', appointmentPhoneInput.value);
    formData.append('datacheck', basicDataCheckCbx.checked);
    formData.append('anacredit', anaCreditCheckCbx.checked);
    formData.append('customengine', customEngineCbx.checked);
    formData.append('freeanalysis', freeAnalysisCbx.checked);

    // H*ney P0t fields
    formData.append('name', appointmentHpName.value);
    formData.append('email', appointmentHpMail.value);

    // Send Post Request for appointment form
    axios({
      method: "post",
      url: "backend/public/appointment.php",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function(response) {
        appointmentModal.hide();
        showNotificationToast('Erfolg!', '', 'Mail wurde versand!');

        // Reset UI
        resetCheckboxes();
        // appointmentSubmitButton.disabled = true;
        appointmentMailInput.value = '';
        appointmentPhoneInput.value = '';
      })
      .catch(function(response) {
        showNotificationToast('Fehler!', 'Danke für Ihr Verständnis', 'Mail konnte nicht gesendet werden!<br>Bitte kontaktieren Sie uns direkt.');
      });
  });

  var appointmentSubmitButton = document.getElementById('appointmentSubmitButton');
  appointmentSubmitButton.addEventListener('click', function(e) {
    e.preventDefault();
    mainAppointmentForm.requestSubmit();
  });
  
  // Init Appointment Modal
  var appointmentModal = new bootstrap.Modal(appointmentModalEl);
  appointmentModalEl.addEventListener('shown.bs.modal', function() {
    appointmentModalEmailInput.focus();
  });

  basicDataCheckBtn.addEventListener('click', function() {
    resetCheckboxes();
    basicDataCheckCbx.checked = true;
    appointmentModal.show();
  });

  anaCreditCheckBtn.addEventListener('click', function() {
    resetCheckboxes();
    anaCreditCheckCbx.checked = true;
    appointmentModal.show();
  });

  customEngineBtn.addEventListener('click', function() {
    resetCheckboxes();
    customEngineCbx.checked = true;
    appointmentModal.show();
  });

  freeAnalysisBtn.addEventListener('click', function() {
    resetCheckboxes();
    freeAnalysisCbx.checked = true;
    appointmentModal.show();
  });
});
